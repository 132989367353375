import React from "react"

import { Stack } from "@kiwicom/orbit-components"
import Text from "@kiwicom/orbit-components/lib/Text"
import TextLink from "@kiwicom/orbit-components/lib/TextLink"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => (
  <DriveLayout title="Tachograph Infringements">
    <Hero title="Tachograph Infringements" />
    <Container size="medium">
      <Stack spacing="XLarge">
        <EmberCard title="Reviewing Your Tachograph Data">
          <EmberCardSection>
            <Stack>
              <Text>
                The best way to avoid infringements is to review your tachograph
                data frequently and then make sure you understand any issues so
                they don't occur again.
              </Text>
              <Text>
                To help you do this, we set every driver up with a Tachogram
                account. This allows you to review your tachograph data at any
                time via the{" "}
                <TextLink href="https://tachogram.com/en/login">
                  Tachogram website
                </TextLink>{" "}
                or their mobile apps. If you don't have a Tachogram account or
                need help then please speak to the Operations Team and they'll
                help you to get set up.
              </Text>
              <Text>
                Your Tachogram account will only show you the data up until the
                last time your card was downloaded. This will generally mean
                sometime during your last shift, assuming you were driving a
                coach fitted with automatic downloads.
              </Text>
              <Text>
                Tachogram has a useful guide to{" "}
                <TextLink
                  external
                  href="https://tachogram.com/en/blog/2018/07/12/reading-and-understanding-tachogram-charts"
                >
                  reading and understanding their charts
                </TextLink>
                . If you have any difficulty understanding your chart, ask the
                Operations Team who can arrange for further training.
              </Text>
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard title="What To Do If You Have An Infringement">
          <EmberCardSection>
            <Stack>
              <Text>There are three main types of infringement.</Text>
              <Text>
                The first is where you have failed to complete a manual entry
                correctly. This could be either you selected the wrong option on
                the tachograph or where you have inserted your card and left it
                for more than 20 seconds without an input (causing it to time
                out).
              </Text>
              <Text>
                The second is where you have accidentally logged the incorrect
                information using the tachograph – for instance, you were on a
                rest but the tachograph was logging "Other Work".
              </Text>
              <Text>
                The third is where you have actually broken the rules. This
                should be exceptionally rare. There are some emergency
                situations in which it is allowed, otherwise you are exposing
                yourself and Ember to serious penalties (including fines or the
                loss of your licence).
              </Text>
              <Text>
                In case of an infringement, you should take a print-out, write
                an explanation of what happened on the back and sign it. You'll
                need to keep this print-out with you whilst driving for the next
                28 days, in case you are stopped.
              </Text>
              <Text>
                You should also inform the Operations Team about what happened
                via your Google Chat room, sending a photo of the back of the
                print-out with the explanation.
              </Text>
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard title="Weekly Debriefs">
          <EmberCardSection>
            <Stack>
              <Text>
                At the end of each week, the operations team will review all
                driver records on Tachogram to identify any infringements that
                have occurred and make a record of the reason.
              </Text>
              <Text>
                If you are found to have an infringement, a debrief report will
                be sent to you via your Google Chat room showing the
                infringement and asking you to confirm what happened. If the
                infringement was caused by a data entry error, the Operations
                Team will update your records on Tachogram but note that you
                still need to keep a print out for 28 days in case of roadside
                checks.
              </Text>
              <Text>
                The debrief reports include a chart that follow the same style
                as all Tachogram charts (see guide above). Gray lines with a "?"
                are unknown periods which occur whenever you've made an error
                with a manual entry on inserting your card. The debrief report
                will also contain a list of infringements below the chart.
              </Text>
              <Text>
                The goal is to have zero infringements each week. This is
                possible by being diligent when using a Tachograph and making
                sure you understand all the rules.
              </Text>
              <Text>
                If you have repeated or serious infringements, we may ask you to
                undergo some additional training. This includes any cases where
                your Tachogram risk score exceeds 25. Very serious or persistent
                violations will ultimately be treated as a disciplinary issue
                because they are a risk to passenger safety and expose Ember to
                significant liability. However, we'd like to avoid this if at
                all possible.
              </Text>
            </Stack>
          </EmberCardSection>
        </EmberCard>
      </Stack>
    </Container>
  </DriveLayout>
)

export default Page
